:root {
  --color-primary: #00244d;
  --color-secondary: #00d9ff;
  --color-accent: #ff3400;
  --color-headings: #00244d;
  --color-body: #00244d;
  --color-body-darker: #00244d;
  --color-border: #ccc;
  --border-radius: 30px;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

/* Typography */
::selection {
  background: var(--color-primary);
  color: #fff;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  letter-spacing: 0.1em;
}

img {
  width: 100%;
}

body {
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
  color: var(--color-body);
  letter-spacing: 0.1em;
  font-weight: normal;
}

h1,
h3 {
  color: var(--color-headings);
  margin-bottom: 1rem;
  line-height: 1.1;
}

h4 {
  color: var(--color-body-darker);
  font-size: 1.4rem;
  font-weight: unset;
}

h1 {
  font-size: 5rem;
}

h2 {
  font-size: 2.4rem;
  font-weight: 300;
}

h3 {
  font-size: 2.2rem;
  line-height: 1.3;
}

p {
  margin-top: 0;
  font-size: 1.3rem;
}

@media screen and (min-width: 1024px) {
  body {
    font-size: 1.8rem;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.4rem;
  }

  h3 {
    font-size: 2rem;
  }
}

/* Links */
a {
  text-decoration: none;
  color: var(--color-body);
}

/* logo */

.img_logo {
  width: 240px;
  margin-left: 16px;
}

@media screen and (min-width: 768px) {
  .img_logo {
    margin-left: 19px;
  }
}

/* Lists */
.list {
  list-style: none;
  padding-left: 0;
}

.list--inline {
  display: inline;
  padding-right: 2rem;
}

.list--inline .list__item {
  display: inline-block;
  margin-right: 2rem;
}

.list--tick .list__item {
  padding-left: 0.5rem;
  margin-bottom: 1rem;
}

@media screen and (min-width: 1024px) {
  .list--tick .list__item {
    padding-left: 0;
  }
}

/* Icons */
.icon {
  width: 30px;
  height: 30px;
}

.icon--small {
  width: 30px;
  height: 30px;
}

.icon--primary {
  fill: var(--color-primary);
}

.icon--white {
  fill: #fff;
}

.icon-container {
  background: #f3f9fa;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.icon-container--accent {
  background: var(--color-accent);
}

/* Buttons */
.btn {
  border-radius: 40px;
  border: 0;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 600;
  margin: 1rem 0;
  outline: 0;
  padding: 2rem 4vw;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.btn .icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  vertical-align: middle;
}

.btn--primary {
  background: var(--color-primary);
  color: #fff;
}

.btn--primary:hover {
  background: #3a8ffd;
}

.btn--secondary {
  background: var(--color-secondary);
  color: #fff;
}

.btn--secondary:hover {
  background: #05cdf0;
}

.btn--accent {
  background: var(--color-accent);
  color: #fff;
}

.btn--accent:hover {
  background: #ec3000;
}

.btn--outline {
  background: #fff;
  color: var(--color-headings);
  border: 2px solid var(--color-headings);
}

.btn--outline:hover {
  background: var(--color-headings);
  color: #fff;
}

.btn--stretched {
  padding-left: 6rem;
  padding-right: 6rem;
}

.btn--block {
  width: 100%;
  display: inline-block;
}

@media screen and (min-width: 1024px) {
  .btn {
    font-size: 1.5rem;
  }
}

/* Inputs */
.input {
  border-radius: var(--border-radius);
  border: 1px solid var(--color-border);
  color: var(--color-headings);
  font-size: 2rem;
  outline: 0;
  padding: 1.5rem 3.5rem;
}

::placeholder {
  color: #cdcbd7;
}

.input-group {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  display: flex;
}

.input-group .input {
  border: 0;
  flex-grow: 1;
  padding: 1.5rem 2rem;
  width: 0;
}

.input-group .btn {
  margin: 4px;
}

@media screen and (min-width: 1024px) {
  .input {
    font-size: 1.5rem;
  }
}

/* Grids */
.grid {
  display: grid;
}

@media screen and (min-width: 768px) {
  .grid--1x2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .grid--1x3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Callouts */
.callout {
  padding: 4rem;
  border-radius: 5px;
}

.callout--primary {
  background: var(--color-primary);
  color: #fff;
}

.callout__heading {
  color: #fff;
  margin-top: 0;
  font-size: 3rem;
}

.callout .btn {
  justify-self: center;
  align-self: center;
}

.callout__content {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .callout .grid--1x2 {
    grid-template-columns: 1fr auto;
  }

  .callout__content {
    text-align: left;
  }

  .callout .btn {
    justify-self: start;
    margin: 0 2rem;
  }
}

/* Collapsibles */
.collapsible__header {
  display: flex;
  justify-content: space-between;
}

.collapsible__heading {
  margin-top: 0;
  font-size: 3rem;
}

.collapsible__chevron {
  transform: rotate(-90deg);
  transition: transform 0.3s;
}

.collapsible__content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.collapsible--expanded .collapsible__chevron {
  transform: rotate(0);
}

.collapsible--expanded .collapsible__content {
  max-height: 100vh;
  opacity: 1;
}

/* Blocks */
.block {
  --padding-vertical: 2rem;
  padding: var(--padding-vertical) 3rem;
}

.block__header {
  margin-bottom: 4rem;
  visibility: hidden;
}

@media screen and (min-width: 768px) {
  .block__header {
    display: none;
  }
}

.block--fullheight {
  min-height: 100vh;
}

.block--content-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.block__heading {
  margin-top: 0;
}

.block--dark {
  background: #000;
  color: #fff;
}

.block--dark h1,
.block--dark h2,
.block--dark h3,
.block--dark a {
  color: #fff;
}
.block--light {
  background: #d8d8d8;
  color: #7b858b;
}

.block--light h1,
.block--light h2,
.block--light h3 {
  color: #000;
}

.block--skewed-right {
  padding-bottom: calc(var(--padding-vertical) + 4rem);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 95%);
}

.block--skewed-left {
  padding-bottom: calc(var(--padding-vertical) + 4rem);
  clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 0% 100%);
}

.container {
  /*   max-width: 1140px;
 */
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .container {
    margin-left: 40px;
  }
}
/* Navigation Bar */
.nav {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  align-items: center;
  z-index: 2;
  background-color: white;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.nav__list {
  width: 100%;
  margin: 0;
  display: block;
  margin-left: 40px;
  font-size: large;
}

.nav__item {
  padding: 0.5rem 2rem;
}

.nav__item:last-of-type {
  border-bottom: none;
}

.nav__item > a {
  color: var(--color-headings);
  transition: color 0.3s;
}

.nav__item > a:hover {
  color: #918ca4;
}

.nav__toggler {
  opacity: 0.5;
  transition: box-shadow 0.15s;
  cursor: pointer;
  margin-left: auto;
  fill: var(--color-body);
}

.nav.collapsible--expanded .nav__toggler {
  opacity: 1;
  box-shadow: 0 0 0 3px #666;
  border-radius: 5px;
}

.nav__brand {
  transform: translateY(5px);
}

.nav__brand__name {
  margin-left: 20px;
  font-size: large;
}
.nav__brand__features {
  display: none;
}

@media screen and (min-width: 768px) {
  .nav__toggler {
    display: none;
  }

  .nav__list {
    display: flex;
    font-size: 1.6rem;
    max-height: 100%;
    opacity: 1;
    flex-direction: column;
    margin-top: 10px;
  }

  .nav__item {
    border: 0;
  }

  .nav__brand__features {
    display: block;
    margin-left: 60px;
    font-size: 1.3rem;
    letter-spacing: 0.1em;
    word-spacing: 0.3em;
    color: var(--color-body-darker);
  }
  .sticky {
    position: unset;
  }
}

/* Hero */

.hero__tagline {
  font-size: 2rem;
  color: #b9c3cf;
  letter-spacing: 1px;
  margin: 2rem 0 5rem;
}

.hero__image__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero__image {
  border-radius: 50%;
  width: 300px;
}

@media screen and (min-width: 768px) {
  .hero__content {
    text-align: left;
    align-self: center;
  }
  .hero__image {
    width: 450px;
  }
}

@media screen and (min-width: 1024px) {
  .hero {
    padding-top: 0;
  }
}

/* Plans Block */
.block-plans .grid {
  gap: 8rem 4rem;
}

.block-plans .card {
  max-width: 500px;
  margin: 0 auto;
}

/* Features */
.feature {
  gap: 4rem 6rem;
  margin: 12rem 0;
}

.feature:first-of-type {
  margin-top: 3rem;
}

.feature__heading {
  margin: 1rem 0;
}

.feature__content {
  order: 1;
}
@media screen and (min-width: 768px) {
  /*   .feature:nth-of-type(even)  */
  .feature__content {
    order: -1;
  }
}

/* Showcase Block */
@media screen and (min-width: 768px) {
  .block-showcase .grid {
    grid-template-columns: 50% 50%;
  }

  .block-showcase__image {
    justify-self: end;
  }

  .block-showcase__image > img {
    width: auto;
    max-width: 700px;
  }
}

/*Font Awesome*/

.fa {
  font-size: 2rem !important;
  padding-left: 1rem;
  padding-right: 1rem;
}

/* Footer */
.footer {
  padding-top: 10rem;
}

.footer a {
  /*   color: #777;
 */
  transition: color 0.3s;
}

.footer a:hover {
  /*   color: #fff;
 */
}

.footer__section {
  padding: 2rem;
  border-bottom: 1px solid #393939;
}

.footer__section .list {
  margin: 0;
}

.footer__heading {
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 1.5;
}

.footer__content {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5;
}

.footer__brand {
  margin-top: 10rem;
  text-align: center;
}

.footer__brand img {
  max-width: 230px;
}

.footer__copyright {
  font-size: 1.5rem;
  opacity: 0.7;
  margin-top: 8px;
}

@media screen and (min-width: 768px) {
  .footer {
    font-size: 1.5rem;
  }
  .footer__sections {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    justify-items: center;
  }

  .footer .collapsible__chevron {
    display: none;
  }

  .footer .collapsible__content {
    opacity: 1;
    max-height: 100%;
  }

  .footer__brand {
    order: -1;
    margin-top: 5rem;
  }

  .footer__copyright {
    font-size: 1.5rem;
  }

  .footer__section {
    border: 0;
  }

  .footer__heading {
    font-size: 1.6rem;
  }
}

/* Sign-up Callout */
.callout-signup {
  transform: translateY(5rem);
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: var(--color-primary);
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: var(--color-primary);
  color: white;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* About Me - Profile */

.profile__picture {
  max-width: 300px;
}

.profile__container {
  align-self: center;
  justify-self: center;
}

.imgSlide {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

@media screen and (min-width: 768px) {
  .imgSlide {
    height: 600px;
  }
}
